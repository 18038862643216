<template>
  <div>
    <CCollapse :show="!collapse">
      <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.yardsGeneralList')}}  </b>
    </CCardHeader>
    <CCard>
      <CCardBody>
        <CustomTabs :active-tab="$store.state.yard.masterOfYardsTabIndex" @update:activeTab="handleTab">
        <CustomTab :title="$t('label.yard')"  valueItemTab="0">
          <Yard />   
        </CustomTab>
        <CustomTab :title="$t('label.groupingOfYards')"  valueItemTab="1">
          <GroupingOfYards/>
        </CustomTab>
      </CustomTabs>
      </CCardBody>
    </CCard>
    </CCollapse>
    <CCollapse :show="collapse">
        <div v-if="collapse">
          <Menu />
        </div>
    </CCollapse>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import Menu from './menu-yard';
import Yard from './yard/yard-index';
import GroupingOfYards from './groupingOfYards/groupingOfYards-index';



function handleTab(tab) {
  this.$store.state.yard.masterOfYardsTabIndex = tab;
}
function data() {
  return {
   
  };
}
export default {
  name: 'index',
  data,
  methods:{
    handleTab,
  },
  destroyed() {
    this.$store.state.yard.masterOfYardsTabIndex = 0;
  },
  components: {
    CustomTabs,
    CustomTab,
    Menu,
    Yard,
    GroupingOfYards
  },
  computed: {
    ...mapState({
      collapse: state => state.yard.collapse,
    })
  },
};
</script>